import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './ResultCard.css';
import { Teacher } from '../../../types/Teacher';
import ProfilePicture from '../../ProfilePicture/ProfilePicture';
import { trackClickEvent } from '../../../utils/functions/trackEvent';
import Rate from '../../Rate';

const DAYS_OF_WEEK = [
  { label: 'DO', index: 0 },
  { label: 'LU', index: 1 },
  { label: 'MA', index: 2 },
  { label: 'MI', index: 3 },
  { label: 'JU', index: 4 },
  { label: 'VI', index: 5 },
  { label: 'SA', index: 6 }
];

const ResultCard = ({ result }: { result: Teacher }) => {
  const handleResultClick = () => {
    trackClickEvent('result_card', result.external_id);
  };

  return (
    <Link
      to={`/teachers/${result.external_id}/profile`}
      className="resultCard"
      onClick={handleResultClick}>
      <Card>
        <CardActionArea>
          <CardContent sx={{ padding: '10px 20px 20px 20px' }}>
            <div className="resultCardHeader">
              <ProfilePicture image_url={result.image_url} />
              <div>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: {
                      sm: '1.5rem',
                      xs: '1rem'
                    }
                  }}
                  className="resultCardName">
                  {result.first_name} {result.last_name}
                  {result.rate && result.rate.average && result.rate.quantity && (
                    <Rate rate={result.rate} />
                  )}
                </Typography>
              </div>
              <div className="resultCardPrice">
                <h4>${result.price_per_hour}</h4>
                <span className="byHour">por hora</span>
              </div>
            </div>
            <div>
              <p>Disponibilidad</p>
              <ul className="availability">
                {DAYS_OF_WEEK.map((day) => (
                  <li
                    key={`teacher-${result.external_id}-day-${day.index}`}
                    className="availabilityDay">
                    <span
                      className={classNames({
                        ['availabilityDayEnabled']: result.available_days_of_week.includes(
                          day.index
                        )
                      })}>
                      {day.label}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <Typography variant="body2" color="text.secondary" className="profileDescription">
              {result.profile_description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default ResultCard;
