import { teacherAdapter } from './adapters/teachers';
import { TeacherInfo } from '../../types/TeacherInfo';

const fetchTeacherProfile = async (teacherId: string): Promise<TeacherInfo> => {
  const teachersUrl = `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/teachers/${teacherId}/profile`;

  try {
    const response = await fetch(teachersUrl);

    return await response.json();
  } catch (error) {
    console.error(error);

    return Promise.reject();
  }
};

export const teacherProfileQueryFunction = (teacherId: string) => ({
  queryKey: [teacherId, 'teacher-profile'],
  queryFn: async (): Promise<TeacherInfo> => fetchTeacherProfile(teacherId).then(teacherAdapter)
});
