import Box from '@mui/material/Box';
import ContactForm from './ContactForm';
import ByNumbers from './ByNumbers';
import './Schools.css';

const Schools = () => {
  return (
    <div className="schools">
      <Box
        sx={{
          background: 'linear-gradient(45deg, #51caa4, #184b76)',
          justifyContent: 'center',
          display: 'flex'
        }}>
        <div className="schools-container">
          <div className="schools-content">
            <div className="schools-title">
              <h1>estudi</h1>
              <h3 className="schools-subtitle">COLEGIOS</h3>
            </div>
            <h2>Ahora #SeAprueba en colegios!</h2>
            <ByNumbers />
          </div>
        </div>
      </Box>
      <ContactForm />
    </div>
  );
};

export default Schools;
