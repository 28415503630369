import React, { useState } from 'react';
import { Alert, Button, CircularProgress, MenuItem, TextField } from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Moment } from 'moment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './TeacherRequest.css';
import { trackClickButtonEvent } from '../../utils/functions/trackEvent';

const PURPOSES: string[] = [
  'Clases todas las semanas porque me cuesta la materia',
  'Aprender uno o más temas',
  'Practicar ejercicios',
  'Entender ejercicios',
  'Resolver guías',
  'Preparar un parcial',
  'Preparar un final',
  'Otro'
];

const ACCEPTED_CONTENT_TYPES: string[] = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.apple.iwork.pages',
  'application/vnd.apple.iwork.numbers',
  'application/vnd.apple.iwork.key',
  'application/vnd.apple.iwork.template',
  'application/vnd.apple.template',
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/gif',
  'text/plain'
];
const REQUIRED_FILE_ERROR: string = 'El material adjunto es requerido';
const FILE_QUANTITY_ERROR: string = 'Se permiten hasta 5 archivos';
const FILE_SIZE_ERROR: string = 'Archivo muy grande, debe ser menor a 30MB';
const GENERIC_ERROR: string = 'Algo salio mal';

const TeacherRequest = () => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [institution, setInstitution] = useState<string | undefined>(undefined);
  const [course, setCourse] = useState<string | undefined>(undefined);
  const [purpose, setPurpose] = useState<string | undefined>(undefined);
  const [context, setContext] = useState<string | undefined>(undefined);
  const [expiration, setExpiration] = useState<Moment | undefined>(undefined);
  const [content, setContent] = useState<File[] | undefined>(undefined);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const [requestSent, setRequestSent] = useState<boolean>(false);

  const handlePhoneNumberChange = (value: any) => {
    setPhoneNumberError(!matchIsValidTel(value));
    setPhoneNumber(value);
  };

  const handleContentChange = (newValues: File[]) => {
    setContent(newValues);
  };

  const validateContentFiles = (files: File[]) => {
    if (files.length > 5) {
      setApiError(FILE_QUANTITY_ERROR);

      return FILE_QUANTITY_ERROR;
    }
    if (files.some((file) => file.size! >= 30 * 1024 * 1024)) {
      setApiError(FILE_SIZE_ERROR);

      return FILE_SIZE_ERROR;
    }

    return '';
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!content || content.length === 0) {
      setApiError(REQUIRED_FILE_ERROR);

      return;
    }
    const contentError: string | undefined = validateContentFiles(content);

    if (contentError) {
      return;
    }
    trackClickButtonEvent('send_teacher_request');
    setApiError(undefined);
    setWaiting(true);

    const formdata = new FormData();
    const payload: string = `Necesidad: ${purpose!}\n${context!}`;

    for (let i = 0; i < content.length; i++) {
      formdata.append('content', content[i]);
    }
    formdata.append('requester_name', name!);
    formdata.append('requester_email', email!);
    formdata.append('requester_phone_number', phoneNumber!);
    formdata.append('institution', institution!);
    formdata.append('course', course!);

    formdata.append('payload', payload);
    if (expiration) {
      formdata.append('expiration_date', expiration.toISOString());
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/teachers/request`,
        requestOptions
      );

      if (response.ok) {
        setRequestSent(true);
      } else {
        setApiError(GENERIC_ERROR);
      }
    } catch (error) {
      console.error(error);
      setApiError(GENERIC_ERROR);
    } finally {
      setWaiting(false);
    }
  };

  if (requestSent) {
    return (
      <div className="teacherRequestSuccess">
        <div className="teacherRequestSuccessTitle">
          <CheckCircleOutlineIcon sx={{ fontSize: '50px', marginTop: '10%' }} />
          <h1>¡Felicitaciones!</h1>
          <div className="teacherRequestSuccessSummary">
            <p>¡Gracias por elegirnos para tus clases particulares!</p>
            <p>Te contactaremos a la brevedad para ayudarte con tu pedido</p>
            <p className="teacherRequestSuccessContact">
              Ante cualquier duda accedé a nuestra <a href="/help">página de ayuda</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="teacherRequest">
      <div className="teacherRequestTitle">Encontremos tu profe ideal</div>
      <div className="teacherRequestContainer">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <form
            className="teacherRequestForm"
            id="teacherRequestForm"
            name="teacher-request-form"
            acceptCharset="utf-8"
            onSubmit={handleSubmit}>
            {phoneNumberError && <Alert severity="error">Teléfono invalido</Alert>}
            {apiError && <Alert severity="error">{apiError}</Alert>}
            <TextField
              id="requester_name"
              key="requester_name"
              label="Nombre"
              placeholder="Nombre"
              variant="outlined"
              margin="dense"
              type="text"
              name="requester_name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              required
            />
            <TextField
              id="requester_email"
              key="requester_email"
              label="Email"
              placeholder="Email"
              variant="outlined"
              margin="dense"
              type="email"
              name="requester_email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              required
            />
            <MuiTelInput
              id="requester_phone_number"
              key="requester_phone_number"
              label="Teléfono"
              placeholder="Teléfono"
              variant="outlined"
              name="requester_phone_number"
              margin="dense"
              forceCallingCode
              disableFormatting
              required
              value={phoneNumber}
              defaultCountry="AR"
              onChange={handlePhoneNumberChange}
            />
            <TextField
              id="institution"
              key="institution"
              label="Universidad"
              placeholder="Institución / universidad"
              variant="outlined"
              margin="dense"
              type="text"
              name="institution"
              value={institution}
              onChange={(e: any) => setInstitution(e.target.value)}
              required
            />
            <TextField
              id="course"
              key="course"
              label="Materia"
              placeholder="Curso / materia"
              variant="outlined"
              margin="dense"
              type="text"
              name="course"
              value={course}
              onChange={(e: any) => setCourse(e.target.value)}
              required
            />
            <TextField
              id="purpose"
              key="purpose"
              label="¿Qué estas necesitando?"
              placeholder="¿Qué estas necesitando?"
              variant="outlined"
              margin="dense"
              name="purpose"
              value={purpose}
              select
              onChange={(e: any) => setPurpose(e.target.value)}
              required>
              {PURPOSES.map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </TextField>
            <TextField
              id="context"
              key="context"
              multiline
              rows={6}
              label="Contanos más"
              placeholder="¿Cómo describirías tu situación con la materia? ¿Qué creés que necesitas?, ¿Para qué carrera?, ¿Con qué frecuencia?"
              variant="outlined"
              margin="dense"
              type="text"
              name="context"
              value={context}
              onChange={(e: any) => setContext(e.target.value)}
              required
            />
            <MuiFileInput
              label="Contenido de referencia"
              helperText="Ej: una guia, un exámen, etc"
              inputProps={{ accept: ACCEPTED_CONTENT_TYPES.join(', ') }}
              InputProps={{
                startAdornment: <AttachFileIcon />
              }}
              multiple
              variant="outlined"
              required
              margin="dense"
              getSizeText={validateContentFiles}
              value={content}
              onChange={handleContentChange}
              hideSizeText
            />
            <DatePicker
              label="¿Necesitas las clases antes de alguna fecha?"
              slotProps={{
                textField: {
                  helperText: '(Opcional) Ej: fecha en la que rendis el examen'
                }
              }}
              sx={{ marginTop: '1%', marginBottom: '1%' }}
              value={expiration}
              format="DD.MM.YYYY"
              disablePast
              onChange={setExpiration as any}
            />
            <div className="teacherRequestFormButton">
              <Button
                type="submit"
                variant="contained"
                disabled={
                  !!phoneNumberError ||
                  !name ||
                  !email ||
                  !phoneNumber ||
                  !institution ||
                  !course ||
                  !purpose ||
                  !content ||
                  waiting
                }>
                {waiting && (
                  <CircularProgress color="inherit" size={25} style={{ marginRight: '10%' }} />
                )}{' '}
                Enviar
              </Button>
            </div>
          </form>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default TeacherRequest;
