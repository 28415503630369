import { Course } from '../../../types/Course';
import { ApiCourse } from '../dtos/ApiCourse';
import { institutionsAdapter } from './institutions';

export function courseAdapter(course: ApiCourse): Course {
  return {
    label: course.name,
    value: course.external_id,
    institution: institutionsAdapter(course.institution),
    notes: course.notes
  };
}
