import { useSearchParams } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { useReservationContext } from '../../providers/ReservationProvider';
import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { trackEvent } from '../../utils/functions/trackEvent';
import './PaymentOutcome.css';

const PaymentSuccess = () => {
  return (
    <div className="paymentTitle">
      <CheckCircleOutlineIcon sx={{ fontSize: '140px' }} />
      <h1>¡Felicitaciones!</h1>
      <div className="paymentSummary">
        <p>¡Gracias por elegirnos para tus clases particulares!</p>
        <p>
          Te llegará un mail confirmando el pago de tu clase (recordá revisar SPAM). Además, en las
          proximas horas vamos crearte un grupo de WhatsApp entre vos y tu profe particular para que
          puedan hablar.
        </p>
        <p className="paymentContact">
          Ante cualquier duda accedé a nuestra <a href="/help">página de ayuda</a>.
        </p>
      </div>
    </div>
  );
};

const PaymentError = () => {
  return (
    <div className="paymentTitle">
      <WarningIcon sx={{ fontSize: 140 }} />
      <h1>Hubo un problema</h1>
      <div>Lo lamentamos. Intentá nuevamente o contactános.</div>
      <p className="paymentContact">
        Ante cualquier duda accedé a nuestra <a href="/help">página de ayuda</a>.
      </p>
      <NavHashLink smooth to="/help#complaintForm">
        ¿Tuviste un problema? Dejanos tu reclamo
      </NavHashLink>
    </div>
  );
};

const PaymentOutcome = () => {
  const [paymentWaiting, setPaymentWaiting] = useState(true);
  let [searchParams] = useSearchParams();
  const reservationId: string | null = searchParams.get('external_reference');
  const { reservationStatus, reservationErrorMessage, verifyReservation } = useReservationContext();

  useEffect(() => {
    const verificationHandler = async () => {
      if (reservationId && paymentWaiting) {
        void (await verifyReservation(reservationId));
      }
    };

    void verificationHandler();
  }, [reservationId]);

  useEffect(() => {
    if (reservationStatus && reservationStatus !== 'in_progress') {
      trackEvent('reservation_payment', 'confirm', reservationStatus);
      setPaymentWaiting(false);
    } else if (reservationErrorMessage) {
      setPaymentWaiting(false);
    }
  }, [paymentWaiting, reservationStatus, reservationErrorMessage]);

  return (
    <div className="payment">
      {paymentWaiting && (
        <div className="paymentWaiting">
          <span>Esperando confirmación del pago...</span>
          <ClipLoader color="#184b76" />
        </div>
      )}
      {!paymentWaiting &&
        (reservationStatus === 'confirmed' ? <PaymentSuccess /> : <PaymentError />)}
    </div>
  );
};

export default PaymentOutcome;
