import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import React from 'react';
import { Avatar, Skeleton } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { getNumberValue, getReservationCount } from '../../../utils/functions/byNumbers';
import './ByNumbers.css';

const ByNumbers = () => {
  const [reservationsCount, setReservationsCount] = useState<number>(0);
  const [coursesCount, setCoursesCount] = useState<number>(0);
  const [teachersCount, setTeachersCount] = useState<number>(0);
  const [studentCount, setStudentCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchReservationsCount = async () => {
      setLoading(true);
      const response = await getReservationCount('2024-04-01');

      setLoading(false);

      if (!response) {
        setError(true);

        return;
      }

      setReservationsCount(response.reservations_count);
      setCoursesCount(response.courses_count);
      setTeachersCount(response.teachers_count);
      setStudentCount(response.teachers_count);
    };

    void fetchReservationsCount();
  }, []);
  const totalReservations: number = 57226 + reservationsCount * 1;
  const totalCourses: number = coursesCount || 280;
  const totalTeachers: number = teachersCount || 84;
  const totalStudents: number = 5000 + studentCount * 1;

  const numbers = [
    {
      type: 'clases',
      value: '+' + getNumberValue(totalReservations),
      sx: { bgcolor: cyan[800], width: 125, height: 125 }
    },
    {
      type: 'alumnos',
      value: '+' + getNumberValue(totalStudents),
      sx: { bgcolor: cyan[700], width: 125, height: 125 }
    },
    {
      type: 'profesores',
      value: '+' + getNumberValue(totalTeachers),
      sx: { bgcolor: cyan[900], width: 125, height: 125 }
    },
    {
      type: 'materias',
      value: '+' + getNumberValue(totalCourses),
      sx: { bgcolor: cyan[600], width: 125, height: 125 }
    }
  ];

  return (
    <React.Fragment>
      <div className="grid">
        {numbers.map((number) => (
          <Avatar sx={number.sx}>
            <div className="numbers-div">
              {loading ? (
                <Skeleton variant="circular" animation="wave" width={128} height={128} />
              ) : (
                <React.Fragment>
                  {number.value} <span>{number.type}</span>
                </React.Fragment>
              )}
            </div>
          </Avatar>
        ))}
      </div>
      {error && (
        <Typography textAlign="center" variant="body2">
          Entre 2018 y 2024
        </Typography>
      )}
    </React.Fragment>
  );
};

export default ByNumbers;
