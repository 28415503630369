import { Course } from '../../types/Course';
import { ApiCourse } from './dtos/ApiCourse';
import { courseAdapter } from './adapters/course';

const fetchCourseQuery = async (courseId: string): Promise<ApiCourse> => {
  const courseUrl = `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/v1/courses/${courseId}`;

  try {
    const response = await fetch(courseUrl);

    return await response.json();
  } catch (error) {
    console.error(error);

    return Promise.reject();
  }
};

export const courseQueryFunction = (courseId: string) => ({
  queryKey: [courseId, 'course'],
  queryFn: async (): Promise<Course> => fetchCourseQuery(courseId).then(courseAdapter)
});
