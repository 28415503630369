import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA4 from 'react-ga4';
import { createBrowserRouter, RouterProvider, useLocation, Outlet } from 'react-router-dom';
import Search from './components/Search';
import ErrorPage from './components/Errors';
import Results from './components/Results';
import TeacherProfile from './components/TeacherProfile';
import Outline from './components/Outline';
import PaymentOutcome from './components/PaymentOutcome';
import Help from './components/Help';
import Landing from './components/Landing';
import TeacherRequest from './components/TeacherRequest';
import Schools from './components/Schools';
import { loader as resultsLoader } from './components/Results/Results.loader';
import { loader as teacherProfileLoader } from './components/TeacherProfile/TeacherProfile.loader';
import { loader as searchLoader } from './components/Search/Search.loader';
import { QueryClient, QueryClientProvider } from 'react-query';
import { EstudiApiQueryClient } from './clients/EstudiApiQueryClient';
import TeacherSearchProvider from './providers/TeacherSearchProvider';
import ReservationProvider from './providers/ReservationProvider';
import StudentUserProvider from './providers/StudentUserProvider';
import './Variables.css';
import './index.css';

//FixMe: Privacy and GDPR Compliance:
// Ensure that you follow privacy regulations like GDPR when collecting user data.
// Provide users with the option to opt out of tracking if required.
ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID!);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();
const estudiClient = new EstudiApiQueryClient(queryClient);

const PageViewTracker = ({ outline }: { outline: boolean }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return outline ? <Outline /> : <Outlet />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageViewTracker outline={false} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        loader: searchLoader(estudiClient),
        element: <Search />
      },
      {
        path: '/institutions/:institutionId',
        loader: searchLoader(estudiClient),
        element: <Search />
      }
    ]
  },
  {
    path: '/teachers',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/teachers',
        loader: resultsLoader(estudiClient),
        element: <Results />
      },
      {
        path: '/teachers/:teacherId/profile',
        loader: teacherProfileLoader(estudiClient),
        element: <TeacherProfile />
      }
    ]
  },
  {
    path: '/reservations',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/reservations/payment',
        element: <PaymentOutcome />
      }
    ]
  },
  {
    path: '/help',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/help',
        element: <Help />
      }
    ]
  },
  {
    path: '/about',
    element: <PageViewTracker outline={false} />,
    children: [
      {
        path: '/about',
        element: <Landing />
      }
    ]
  },
  {
    path: '/schools',
    element: <PageViewTracker outline={false} />,
    children: [
      {
        path: '/schools',
        element: <Schools />
      }
    ]
  },
  {
    path: '/request',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/request',
        element: <TeacherRequest />
      }
    ]
  }
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <TeacherSearchProvider>
        <ReservationProvider>
          <StudentUserProvider>
            <RouterProvider router={router} />
          </StudentUserProvider>
        </ReservationProvider>
      </TeacherSearchProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
