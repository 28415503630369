export const getNumberValue = (value: number): string => {
  const roundedValue = Math.round(value / 50) * 50;

  if (value < 2000) {
    return '' + roundedValue;
  }

  const reducedNumber = Math.floor(roundedValue / 1000);

  return `${reducedNumber}mil`;
};

export const getReservationCount = async (fromDate: string) => {
  const myHeaders = new Headers();

  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('X-Workflow-Api-Key', process.env.REACT_APP_RESOURCES_API_KEY!);
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      from_date: fromDate
    })
  };

  try {
    const response = await fetch(
      'https://proxy.estudi.com.ar/?https://api.retool.com/v1/workflows/9618f525-6737-478a-8a78-884ed9421d07/startTrigger',
      requestOptions
    );

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
};
