import { Teacher } from '../../types/Teacher';
import { teacherAdapter } from './adapters/teachers';

const fetchTeachers = async (
  institutionId: string,
  courseId: string
): Promise<{ teachers: Teacher[] }> => {
  const teachersUrl = `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/teachers/search?courseId=${courseId}&institutionId=${institutionId}`;

  try {
    const response = await fetch(teachersUrl);

    return await response.json();
  } catch (error) {
    console.error(error);

    return Promise.reject();
  }
};

export const teachersQueryFunction = (institutionId: string, courseId: string) => ({
  queryKey: [institutionId, courseId, 'teachers'],
  queryFn: async (): Promise<Teacher[]> =>
    fetchTeachers(institutionId, courseId).then((response) =>
      response.teachers.map((teacher: Teacher) => teacherAdapter(teacher))
    )
});
