import React, { useEffect, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckoutModal from '../../CheckoutModal';
import { ClipLoader } from 'react-spinners';
import './ReservationCheckout.css';
import { TeacherInfo } from '../../../types/TeacherInfo';
import { dateOptions, formatDate, timeOptions } from '../../../utils/functions/formatDate';
import ReservationForm from './ReservationForm/ReservationForm';
import { useReservationContext } from '../../../providers/ReservationProvider';
import Button from '@mui/material/Button';
import { trackClickButtonEvent, trackEvent } from '../../../utils/functions/trackEvent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { Alert } from '@mui/material';

const PaymentSuccess = () => {
  return (
    <div className="paymentTitle">
      <CheckCircleOutlineIcon sx={{ fontSize: '4rem' }} />
      <h1>¡Pago confirmado!</h1>
      <div className="paymentSummary">
        <p className="congratDescription">
          Te llegará un mail confirmando el pago y en las proximas horas vamos crearte un grupo de
          WhatsApp entre vos y tu profe particular para que puedan hablar.
        </p>
      </div>
    </div>
  );
};

const PaymentError = () => {
  return (
    <div className="paymentTitle">
      <WarningIcon sx={{ fontSize: '4rem' }} />
      <h1>No pudimos confirmar tu pago</h1>
      <div className="paymentSummary">
        <p className="congratDescription">
          Lo lamentamos. Si pagaste y fue rechazado, intentá nuevamente con otro medio de pago. Si
          se agoto tu tiempo para pagar, volve a intentar nuevamente.
        </p>
      </div>
    </div>
  );
};

interface ReservationCheckoutProps {
  onBack: () => void;
  teacher: TeacherInfo;
  start: Date;
  end: Date;
  reservationType: string;
}

const ReservationCheckout = ({
  onBack,
  teacher,
  start,
  end,
  reservationType
}: ReservationCheckoutProps) => {
  const [checkoutWaiting, setCheckoutWaiting] = useState(false);
  const [paymentWaiting, setPaymentWaiting] = useState(false);
  const paymentSubmittedRef = useRef<boolean>(false);
  const {
    paymentIntentionId,
    paymentIntentionAmount,
    reservationAmount,
    reservationStatus,
    reservationErrorMessage,
    clearContext,
    cancelReservation
  } = useReservationContext();

  useEffect(() => {
    return () => {
      // When the component is unmounted, we should cancel the ongoing reservation
      if (!paymentSubmittedRef.current) {
        void cancelReservation();
      }
    };
  }, [cancelReservation]);

  const handleOnSubmitPayment = () => {
    paymentSubmittedRef.current = true;
    trackClickButtonEvent('mercado_pago_pay');
    setPaymentWaiting(true);
  };

  useEffect(() => {
    if (reservationErrorMessage) {
      setCheckoutWaiting(false);
    }
  }, [reservationErrorMessage]);

  useEffect(() => {
    if (reservationStatus && reservationStatus !== 'in_progress') {
      trackEvent('reservation_payment', 'confirm', reservationStatus);
      setPaymentWaiting(false);
    }
  }, [reservationStatus]);

  const handleOnReservationFormSubmit = () => {
    setCheckoutWaiting(true);
  };

  const handleCheckoutReady = () => {
    setCheckoutWaiting(false);
  };

  const handleCancelReservationAndBack = async () => {
    // This makes the reservation to be cancel twice, but it clears the calendar before loading it
    await cancelReservation();
    trackClickButtonEvent('back_from_checkout_to_calendar');
    onBack();
  };

  const handleBackToCalendar = () => {
    trackClickButtonEvent('back_from_reservation_form_to_calendar');
    onBack();
  };

  const handleBackToCalendarPostPayment = () => {
    trackClickButtonEvent('back_from_confirmed_reservation_to_calendar');
    clearContext(); //FixMe: remove this when Safari state caching issue has been resolved
    onBack();
  };

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: {
              sm: '2rem',
              xs: '1.5rem'
            }
          }}>
          {paymentIntentionId
            ? '¡Ya casi! Paga y ya tenes lista tu clase'
            : `Rellena estos datos y ${reservationType.toLowerCase()} tu clase`}
        </Typography>
        <table className="reservationInfo">
          <tbody>
            <tr>
              <td className="reservationInfoHeader">Profesor:</td>
              <td>
                {teacher.first_name} {teacher.last_name}
              </td>
            </tr>
            <tr>
              <td className="reservationInfoHeader">Fecha:</td>
              <td>{formatDate(start, dateOptions)}</td>
            </tr>
            <tr>
              <td className="reservationInfoHeader">Horario:</td>
              <td>
                {formatDate(start, timeOptions)} a {formatDate(end, timeOptions)}
              </td>
            </tr>
            <tr>
              <br />
            </tr>
            {reservationAmount && (
              <tr>
                <td className="reservationInfoHeader">Total de la clase: </td>
                <td>${reservationAmount}</td>
              </tr>
            )}
            {paymentIntentionAmount && reservationAmount! > paymentIntentionAmount! && (
              <React.Fragment>
                <tr>
                  <td className="reservationInfoHeader">Pago por reserva: </td>
                  <td>
                    ${paymentIntentionAmount} <span>(se descontará del total)</span>
                  </td>
                </tr>
                <span className="reservationSub">
                  El restante de ${reservationAmount! - paymentIntentionAmount} deberá ser abonado
                  directamente al profesor.
                </span>
              </React.Fragment>
            )}
          </tbody>
        </table>
        <Alert severity="info">
          Una vez realizada la reserva se creará un grupo de WhatsApp donde podrás hablar con tu
          profesor.
        </Alert>
        {!paymentIntentionId && !checkoutWaiting && (
          <ReservationForm
            onSubmit={handleOnReservationFormSubmit}
            onBack={handleBackToCalendar}
            teacher={teacher}
            start={start}
            end={end}
          />
        )}
        {checkoutWaiting && (
          <div className="checkoutSpinner">
            <span>Aguarde un instante</span>
            <ClipLoader color="#184b76" />
          </div>
        )}
        {paymentIntentionId && (
          <div>
            {paymentWaiting && (
              <div className="checkoutSpinner">
                <span>Esperando confirmación del pago...</span>
                <ClipLoader color="#184b76" />
              </div>
            )}
            {reservationStatus !== 'in_progress' && (
              <div className="checkoutSpinner">
                {reservationStatus === 'confirmed' ? <PaymentSuccess /> : <PaymentError />}
                <Button variant="outlined" onClick={handleBackToCalendarPostPayment}>
                  Volver al calendario
                </Button>
              </div>
            )}
            {reservationStatus === 'in_progress' && (
              <React.Fragment>
                <CheckoutModal
                  paymentIntentionId={paymentIntentionId}
                  onReady={handleCheckoutReady}
                  onSubmit={handleOnSubmitPayment}
                />
                <Button variant="outlined" color="error" onClick={handleCancelReservationAndBack}>
                  Cancelar y volver al calendario
                </Button>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize="0.8rem"
                  color="grey"
                  sx={{ marginTop: '2%' }}>
                  Una vez abierto MercadoPago, si no efectuas el pago, tu horario quedará{' '}
                  <b>"Pendiente"</b> en el calendario y tendrás que esperar <b>10 minutos</b> para
                  poder volver a intentar reservarlo.
                </Typography>
              </React.Fragment>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ReservationCheckout;
