import { Rating } from '@mui/material';
import { Rate } from '../../types/Rate';
import './Rate.css';

const RateComponent = ({ rate }: { rate: Rate }) => {
  return (
    <div className="rate">
      <div className="rateValue">{rate.average.toFixed(1)}</div>
      <Rating name="read-only" value={rate.average} readOnly size="small" precision={0.1} />
      <div className="rateValue">({rate.quantity})</div>
    </div>
  );
};

export default RateComponent;
